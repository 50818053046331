import {Injectable} from '@angular/core';
import {InterfaceService} from '@app/interfaces';
import {HttpClient} from '@angular/common/http';
import {catchError, tap, map} from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class AmazonAwsService extends InterfaceService {

    constructor(private http: HttpClient) {
        super();
    }

    createOrUpdate(data) {
        return this.http.post(this.getApiUrl(`amazon-aws-credentials/`), JSON.stringify(data), this.getHttpOptions('json'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );
    }

    verify_registry(data) {
        return this.http.post(this.getApiUrl(`amazon-aws-credentials/verify-registry/`), JSON.stringify(data), this.getHttpOptions('json'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );
    }

    getExistingRegistryData(id) {
        const url = `amazon-aws-credentials/${id}/get-existing-registry-data/`;
        return this.http.get(this.getApiUrl(url), this.getHttpOptions('json'))
            .pipe(
                map(Response => Response),
                catchError(this.handleError)
            );
    }
    
    deletedata(id, reg_id) {
        const url = this.getApiUrl(`amazon-aws-credentials/${id}/delete-data/`, {'id': reg_id});
        return this.http.get(url, this.getHttpOptions('json'))
            .pipe(
                map(Response => Response),
                catchError(this.handleError)
            );
    }

    check_exists_name(data, id) {
        const url = `amazon-aws-credentials/${id}/check-exists-name/`;
        return this.http.post(this.getApiUrl(url), JSON.stringify(data), this.getHttpOptions('json'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );
    }

    verifyAwsCredentials(payload:any, applicationId:any) {
        const url = `amazon-aws-credentials/${applicationId}/verify-aws-credential/`;
        return this.http.post(this.getApiUrl(url), JSON.stringify(payload), this.getHttpOptions('json'))
        .pipe(
            tap(Res => Res),
            catchError(this.handleError)
        );
    }
}
